<template>
  <div v-if="invoicesLoaded">
    <div class="flex items-center mb-4 mobile:flex-col">
      <h2 class="mr-4 mobile:mr-0 mobile:mb-4">Arved</h2>
      <div class="flex items-center justify-center mobile:mb-4">
        <div
          class="items-center flex flex-row cursor-pointer relative hover:bg-offwhite duration-50 mobile:mb-2"
          @click="statusMenuActive = !statusMenuActive"
          :class="statusMenuActive ? 'rounded-t-xl' : 'rounded-xl'"
        >
          <span v-if="status" v-html="parseDraftInvoiceStatus(status)" />
          <span v-if="!status" class="pl-4 pr-8 py-2"
            >Filtreeri staatuse järgi</span
          >
          <span class="typcn typcn-arrow-sorted-down mx-3"></span>
          <div class="status-menu mobile:z-10" v-if="statusMenuActive">
            <span
              v-for="status in statusFilters"
              :key="status.status"
              class="status-menu-item cursor-pointer"
              v-html="parseDraftInvoiceStatus(status.status)"
              @click="filterInvoices(status.status)"
            ></span>
          </div>
        </div>
      </div>
      <div class="mx-2 mobile:ml-0 mobile:mb-6">
        <button class="new-button-danger" v-if="status" @click="clearFilter">
          <span class="icon"
            ><img src="/bc21/trash.svg" class="h-5 filter-to-white"
          /></span>
          <span class="label">Tühjenda</span>
        </button>
      </div>
      <button
        class="alt-button-green ml-2"
        v-if="
          ['R0', 'R1', 'R2'].includes(
            $store.state.companyData.activeCompany.worker_permission
          )
        "
        @click="addInvoiceView = true"
      >
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa arve</span>
      </button>
    </div>
    <div class="flex flex-row mobile:flex-col" v-if="addInvoiceView">
      <new-invoice> </new-invoice>
    </div>
    <div v-if="!addInvoiceView">
      <company-invoice-card
        v-for="invoice in filteredInvoices"
        :key="invoice.id"
        :invoice="invoice"
      >
      </company-invoice-card>
    </div>
  </div>
</template>

<script>
import CompanyInvoiceCard from "@/components/company/invoices/CompanyInvoiceCard";
import StatusParser from "@/assets/mixins/StatusParser";
import NewInvoice from "@/components/company/invoices/NewInvoice";

const statusFilters = [
  {
    text: "Aktiivne",
    status: 1
  },
  {
    text: "Käigus",
    status: 2
  },
  {
    text: "Probleem",
    status: 4
  },
  {
    text: "Lõpetatud",
    status: 5
  }
];

export default {
  name: "CompanyInvoices",
  components: { NewInvoice, CompanyInvoiceCard },
  data() {
    return {
      invoicesLoaded: false,
      invoices: [],
      statusFilters: statusFilters,
      filteredInvoices: [],
      statusMenuActive: false,
      status: 0,
      addInvoiceView: false
    };
  },
  mixins: [StatusParser],
  methods: {
    loadInvoices() {
      this.apiRequest(
        "notes/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/invoices/",
        "get",
        true
      ).then(res => {
        this.invoices = res.data;
        this.filteredInvoices = res.data;
        this.invoicesLoaded = true;
      });
    },
    filterInvoices(filterStatus) {
      this.invoicesLoaded = false;
      this.status = filterStatus;
      this.filteredInvoices = this.invoices.filter(
        x => x.status === filterStatus
      );
      this.invoicesLoaded = true;
    },
    clearFilter() {
      this.status = 0;
      this.filteredInvoices = this.invoices;
    }
  },
  mounted() {
    this.loadInvoices();
  }
};
</script>
