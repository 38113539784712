<template>
  <div>
    <div class="flex flex-row w-full mb-3">
      <button class="new-button-danger" @click="closeView">
        <span class="typcn typcn-times icon"></span>
        <span class="label">Sulge</span>
      </button>
    </div>
    <div class="flex flex-row w-full mb-3" v-if="invoiceDataLoaded">
      <company-invoice-info
        :invoice-data="invoiceData"
        @pdfInfoUpdated="retrieveCompanyInvoice"
      />
      <div class="flex flex-col w-1/2 ml-3 px-5">
        <h3 class="mb-2">Arve saaja</h3>
        <div>
          {{ invoiceData.pdf_info.client }}
        </div>
      </div>
    </div>
    <div class="flex mb-3">
      <company-invoice-services></company-invoice-services>
    </div>
    <div v-if="pdfViewActive && pdfSrc">
      <pdf-modal :pdf-src="pdfSrc" @closeModal="closePdfView"></pdf-modal>
    </div>
    <div class="flex flex-row">
      <button class="new-button-green" @click="getPreview">
        <span class="typcn typcn-document-text icon"></span>
        <span class="label">Eelvaade</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PdfModal from "@/components/reusable/PdfModal";
import moment from "moment";
import { ee } from "vuejs-datepicker/dist/locale";
import CompanyInvoiceServices from "@/components/company/invoices/services/CompanyInvoiceServices";
import CompanyInvoiceInfo from "@/components/company/invoices/CompanyInvoiceInfo";

export default {
  name: "CompanyInvoice",
  components: { CompanyInvoiceInfo, CompanyInvoiceServices, PdfModal },
  data() {
    return {
      ee: ee,
      moment: moment,
      invoiceData: null,
      invoiceLoaded: false,
      pdfSrc: null,
      pdfViewActive: false,
      invoiceDataLoaded: false
    };
  },
  mounted() {
    this.retrieveCompanyInvoice();
  },
  beforeDestroy() {
    this.closeView();
  },
  methods: {
    closeView() {
      this.$store.dispatch("invoiceViewer/closeCompanyInvoice");
    },
    retrieveCompanyInvoice() {
      this.apiRequest(
        "notes/invoice/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.$store.state.invoiceViewer.companyInvoice.data +
          "/",
        "get",
        true
      ).then(res => {
        this.invoiceData = res.data;
        this.invoiceDataLoaded = true;
      });
    },
    getPreview() {
      this.$store.state.loading = true;
      this.pdfViewActive = false;
      const self = this;

      return axios.get(
        process.env.VUE_APP_API_URL +
          "notes/invoice/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/" +
          this.invoiceData.id +
          "/pdf/",
        {
          responseType: "blob",
          headers: {
            ["Authorization"]: "Bearer " + localStorage.getItem("access")
          },
          transformResponse: [
            function(data) {
              const blob = new Blob([data]);
              self.pdfSrc = URL.createObjectURL(blob);
              self.pdfViewActive = true;
              self.$store.state.loading = false;
            }
          ]
        }
      );
    },
    closePdfView() {
      this.pdfViewActive = false;
      this.pdfSrc = "";
    }
  }
};
</script>
