<template>
  <div class="flex flex-col w-full z-10" v-if="itemsLoaded">
    <h4 class="mb-3">Teenused</h4>
    <div class="w-full grid grid-cols-12 service-ex-row-headers text-sm">
      <div
        class="flex col-span-3 mobile:col-span-5 justify-start font-medium justify-start mobile:hidden pl-2"
      >
        Kirjeldus
      </div>
      <div class="flex font-medium mobile:hidden justify-start pl-2">
        Kogus
      </div>
      <div class="flex font-medium  mobile:hidden justify-start pl-2">
        Ühik
      </div>
      <div class="flex font-medium  mobile:hidden justify-start pl-2">
        <span class="truncate"
          >Ühikuhind<span class="medium:hidden">(€)</span></span
        >
      </div>
      <div class="flex font-medium  mobile:hidden justify-start pl-2">
        <span class="truncate">Allahindlus %</span>
      </div>
      <div class="flex font-medium  mobile:hidden justify-start pl-2">
        <span class="truncate"
          >Summa <span class="medium:hidden">(-km)</span></span
        >
      </div>
      <div class="flex font-medium  mobile:hidden justify-start pl-2">
        KM%
      </div>
      <div class="flex font-medium  mobile:hidden justify-start pl-2">
        KM
      </div>
      <div
        class="flex font-medium mobile:col-span-5 justify-start mobile:hidden pl-2"
      >
        Summa
      </div>
    </div>
    <div
      class="w-full h-1 rounded-full mt-2 duration-100 bg-offwhite mb-1"
    ></div>
    <div class="flex flex-col service-container z-10" id="service-container">
      <div class="flex flex-col" v-for="item in newServices" :key="item.index">
        <new-service-row
          :service="item"
          @removeService="removeService"
          :index="item.index"
          :serviceTemplates="serviceTemplates"
          :activeTemplateSelector="activeTemplateSelector"
          :is-white="true"
          class="mb-1"
          @openTemplateSelector="changeActiveTemplateSelector"
          @serviceUpdated="serviceUpdated"
        ></new-service-row>
        <div class="flex w-full h-1 bg-offwhite mb-1"></div>
      </div>
    </div>
    <div class="flex py-3">
      <button @click="addService" class="alt-button-green">
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Lisa rida</span>
      </button>
    </div>
  </div>
</template>

<script>
import NewServiceRow from "./NewServiceRow";

export default {
  name: "NewServices",
  components: {
    NewServiceRow
  },
  props: {
    group: Number
  },
  data() {
    return {
      newServices: [],
      itemsLoaded: false,
      serviceTemplates: [],
      activeTemplateSelector: null,
      serviceIndex: 1
    };
  },
  mounted() {
    this.addService();
    this.itemsLoaded = true;
    this.loadTemplates();
  },

  methods: {
    loadTemplates() {
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/details/",
        "get",
        true
      ).then(res => {
        this.detailTemplates = res.data.filter(x => x.type === 1);
      });
      this.apiRequest(
        "projects/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/templates/items/",
        "get",
        true
      ).then(res => {
        this.serviceTemplates = res.data;
      });
    },
    addService() {
      let data = {
        index: this.serviceIndex,
        name: "",
        price: 0,
        amount: 0,
        unit: 1,
        vat: 20,
        discount: 0
      };
      if (this.group) {
        data.group = this.group;
      }
      this.serviceIndex += 1;
      this.newServices.push(data);
      setTimeout(function() {
        document
          .getElementById("service-container")
          .scroll({ left: 0, top: 999999, behavior: "smooth" });
      }, 100);
    },
    removeService(e) {
      this.newServices.splice(
        this.newServices.findIndex(item => item.index === e),
        1
      );
    },
    changeActiveTemplateSelector(e) {
      this.activeTemplateSelector = e.id;
    },
    serviceUpdated(e) {
      let itemToChange = this.newServices.findIndex(
        item => item.index === e.index
      );
      this.newServices[itemToChange] = e;
      this.$emit("serviceUpdated", {
        group: this.group,
        items: this.newServices
      });
    }
  }
};
</script>
