<template>
  <div
    class="flex bg-white rounded-gwdhalf items-center flex-col cursor-pointer duration-100"
    @click="openCompanyInvoice"
  >
    <div
      class="flex w-full justify-center mobile:justify-between items-center top-row py-3 hover:bg-offwhite hover:bg-opacity-50 px-3 mobile:flex-wrap"
    >
      <div class="w-3/12 flex justify-start font-medium mobile:w-6/12">
        <span>Arve #{{ invoice.invoice_index }}</span>
      </div>
      <div class="w-3/12 mobile:justify-end flex items-center mobile:w-6/12">
        <span
          class="bg-green font-semibold text-white rounded-gwdfull shadow w-1 h-8 justify-center flex text-xl mr-2"
        ></span>
        <span class="font-medium">{{ invoice.price }}€</span>
      </div>
      <div class="w-3/12 flex justify-start mobile:w-4/12">
        {{ moment(invoice.created_at).format("DD.MM.YYYY") }}
      </div>
      <div class="flex relative w-3/12 mobile:justify-end mobile:w-8/12">
        <div
          v-if="
            $store.state.companyData.activeCompany.worker_permission !== 'R6'
          "
          v-html="parseDraftInvoiceStatus(invoice.status)"
        ></div>
      </div>
    </div>
    <div class="w-full h-1 rounded-full bg-offwhite"></div>
  </div>
</template>
<script>
import StatusParser from "@/assets/mixins/StatusParser";
import moment from "moment";

export default {
  name: "company-invoice-card",
  props: {
    invoice: Object
  },
  data() {
    return {
      moment: moment
    };
  },
  mixins: [StatusParser],
  methods: {
    openCompanyInvoice() {
      this.$store.dispatch("invoiceViewer/openCompanyInvoice", this.invoice.id);
    }
  }
};
</script>
