<template>
  <div>
    <div class="flex flex-row">
      <input type="text" v-model="newGroupText" />
    </div>
    <button class="alt-button-green" @click="addNewGroup">
      <span class="typcn typcn-plus icon"></span>
      <span class="label">Lisa grupp</span>
    </button>

    <div
      class="flex flex-col"
      v-for="group in existingGroups"
      :key="group.index"
    >
      {{ group.index }} {{ group.name }}
      <services
        ref="services"
        @serviceUpdated="serviceUpdated"
        :group="group.index"
      ></services>
    </div>
  </div>
</template>

<script>
import Services from "./NewServices";

export default {
  name: "ServiceRowGroup",
  components: { Services },
  data() {
    return {
      existingGroups: [],
      newGroupText: "",
      groupIndex: 1
    };
  },
  methods: {
    addNewGroup() {
      this.existingGroups.push({
        index: this.groupIndex,
        name: this.newGroupText
      });
      this.groupIndex++;
      this.newGroupText = "";
    },
    serviceUpdated(e) {
      let group = this.existingGroups.find(x => x.index === e.group);
      e.items.map(x => (x.group = group.name));
      group.items = e.items;
      this.$emit("serviceUpdated", {
        items: this.existingGroups.map(x => x.items).flat()
      });
    }
  }
};
</script>

<style scoped></style>
