<template>
  <div class="flex w-full flex-col">
    <div class="flex flex-row w-full mb-3">
      <div class="flex flex-col w-1/2 mr-3 px-5">
        <form-label
          :title="'Arve nr'"
          :required="true"
          :valid="!$v.pdfData.invoice_nr.$invalid && !invoiceNrInUse"
          :error="$v.pdfData.invoice_nr.$error || invoiceNrInUse"
          :error-text="
            $v.pdfData.invoice_nr.$error
              ? 'Arve number on kohustuslik'
              : invoiceNrInUse
              ? 'Arve number on juba kasutuses!'
              : ''
          "
        ></form-label>
        <input
          v-model="pdfData.invoice_nr"
          placeholder="Arve nr"
          pattern="^\d+(?:\.\d{1,2})?$"
          class="mb-2"
        />
        <div class="flex flex-row items-center mb-2 w-full">
          <span class="mr-2">Kreeditarve</span>
          <base-checkbox
            class="ml-2"
            :base-val="pdfData.credit"
            @valueChanged="
              e => {
                pdfData.credit = e;
              }
            "
          ></base-checkbox>
        </div>
        <span class="text-sm font-medium ml-2" v-if="pdfData.credit"
          >Alusarve<span class="font-medium"></span
        ></span>
        <input
          v-if="pdfData.credit"
          class="mb-2"
          type="text"
          v-model="pdfData.base_invoice"
          placeholder="Alusarve"
          :class="
            $v.$error && $v.pdfData.base_invoice.$invalid
              ? 'shadow-dangeroutline'
              : ''
          "
        />
        <form-label
          :title="'Kuupäev'"
          :required="true"
          :valid="!$v.pdfData.invoice_date.$invalid"
          :error="$v.pdfData.invoice_date.$error"
        ></form-label>
        <div class="flex flex-row items-center rounded-xl w-full mb-2">
          <date-picker
            v-model="pdfData.invoice_date"
            :class="[topCalendarShowing ? 'is-active' : '']"
            @opened="handleTopCalendarShow"
            @closed="handleTopCalendarClose"
            :language="ee"
            :format="formatDate"
            :monday-first="true"
            :disabled-dates="disabledDates"
          />
        </div>
        <form-label :title="'Tähtaeg'" :required="false"></form-label>
        <div class="flex flex-row items-center rounded-xl w-full mb-2">
          <date-picker
            v-model="pdfData.payment_date"
            :class="[topCalendarShowing ? 'is-active' : '']"
            @opened="handleTopCalendarShow"
            @closed="handleTopCalendarClose"
            :language="ee"
            :format="formatDate"
            :monday-first="true"
            :disabled-dates="disabledPaymentDates"
            class="mr-2"
          />
          <div v-if="!pdfData.payment_date" class="w-6/12 ml-2">
            <select
              class="add-offer-input"
              v-model="tempExpiryDate"
              @change="changeExpiryDate"
            >
              <option value="null" disabled class="text-bordergrey"
                >Vali päevade arv</option
              >
              <option :value="7">7 päeva</option>
              <option :value="14">14 päeva</option>
              <option :value="21">21 päeva</option>
              <option :value="30">30 päeva</option>
            </select>
          </div>
          <div v-if="pdfData.payment_date" @click="removePaymentDate">
            <button class="new-button-danger">
              <span class="typcn typcn-times icon"></span>
            </button>
          </div>
        </div>
        <div class="flex flex-col">
          <form-label
            :title="'Viitenumber'"
            :required="false"
            :valid="!$v.pdfData.ref_nr.$invalid"
            :error="$v.pdfData.ref_nr.$error"
            :error-text="'2-20 kohaline number'"
          ></form-label>
          <input type="text" class="mb-2" v-model="pdfData.ref_nr" />
        </div>
      </div>
      <div class="flex flex-col w-1/2 ml-3 px-5">
        <h3 class="mb-2">Arve saaja</h3>
        <invoice-client-selector
          @clientDataUpdated="handleClientDataUpdate"
        ></invoice-client-selector>
      </div>
    </div>
    <div class="flex w-full rounded-xl shadow mb-4">
      <div
        class="client-selector-tab border-r rounded-l-xl w-1/2"
        @click="groups = false"
        :class="!groups ? 'client-selector-tab-active' : ''"
      >
        <span class="typcn typcn-plus mr-2"></span>
        <span>Ei ole grupid</span>
      </div>
      <div
        class="client-selector-tab border-l rounded-r-xl w-1/2"
        @click="groups = true"
        :class="groups ? 'client-selector-tab-active' : ''"
      >
        <span class="typcn typcn-th-list mr-2"></span>
        <span>Grupid</span>
      </div>
    </div>
    <div class="flex mb-4 w-full flex-col">
      <div v-if="groups">
        <service-row-group @serviceUpdated="serviceUpdated"></service-row-group>
      </div>
      <div v-if="!groups">
        <services ref="services" @serviceUpdated="serviceUpdated"></services>
      </div>
    </div>
    <div class="flex flex-row">
      <button class="new-button-green" @click="saveNewInvoice">
        <span class="typcn typcn-plus icon"></span>
        <span class="label">Salvesta</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { maxLength, required, minLength } from "vuelidate/lib/validators";
import DatePicker from "vuejs-datepicker";
import InvoiceClientSelector from "@/components/company/clients/invoice/InvoiceClientSelector";
import Services from "./services/NewServices";
import ServiceRowGroup from "./services/ServiceRowGroup";
import debounce from "lodash/debounce";

export default {
  name: "NewInvoice",
  components: {
    ServiceRowGroup,
    Services,
    InvoiceClientSelector,
    DatePicker
  },
  data() {
    return {
      ee: ee,
      groups: false,
      pdfData: {
        is_company: false,
        invoice_nr: "",
        credit: false,
        base_invoice: "",
        payment_date: null,
        invoice_date: null,
        ref_nr: "",
        currency: "EUR",
        client: null,
        interest_rate: 0
      },
      items: [],
      saving: false,
      tempExpiryDate: null,
      topCalendarShowing: false,
      invoiceNrInUse: false
    };
  },
  methods: {
    serviceUpdated(e) {
      this.items = e.items;
    },
    saveNewInvoice() {
      if (
        this.pdfData.client &&
        Object.keys(this.pdfData.client).length > 0 &&
        !this.pdfData.client.existing
      ) {
        this.saveClient();
      } else {
        this.pdfData.client = this.pdfData.client.id;
        this.saveInvoice();
      }
    },
    saveClient() {
      this.apiRequest(
        "company/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/clients/add/",
        "post",
        true,
        this.pdfData.client
      ).then(res => {
        if (res.status === 200) {
          this.pdfData.client = res.data.client_id;
          this.saveInvoice();
        }
      });
    },
    saveInvoice() {
      let data = {
        invoice_nr: this.pdfData.invoice_nr,
        credit: this.pdfData.credit,
        base_invoice: this.pdfData.base_invoice,
        payment_date: this.paymentDateFormatted,
        invoice_date: this.invoiceDateFormatted,
        ref_nr: this.pdfData.ref_nr,
        currency: "EUR",
        client: this.pdfData.client,
        interest_rate: this.pdfData.interest_rate
      };
      this.apiRequest(
        "notes/invoice/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/create/",
        "post",
        true,
        data
      ).then(res => {
        if (res.status === 201) {
          this.saveInvoiceItems(res.data.invoice_id);
        }
      });
    },
    saveInvoiceItems(invoiceID) {
      if (this.items.length > 0) {
        this.apiRequest(
          "notes/invoice/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/" +
            invoiceID +
            "/items/create/",
          "post",
          true,
          this.items
        ).then(res => {
          console.log(res);
        });
      }
    },
    handleTopCalendarShow() {
      this.topCalendarShowing = true;
    },
    handleTopCalendarClose() {
      this.topCalendarShowing = false;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    changeExpiryDate() {
      if (this.pdfData.invoice_date) {
        this.pdfData.payment_date = moment(this.pdfData.invoice_date)
          .add(this.tempExpiryDate, "days")
          .toDate();
      } else {
        this.pdfData.payment_date = moment()
          .add(this.tempExpiryDate, "days")
          .toDate();
      }
      this.tempExpiryDate = null;
    },
    removeInvoiceDate() {
      this.pdfData.invoice_date = null;
    },
    removePaymentDate() {
      this.pdfData.payment_date = null;
    },
    handleClientDataUpdate(e) {
      this.pdfData.client = e;
    },
    initDates() {
      this.pdfData.invoice_date = moment().toDate();
    },
    debounceCheckChanges: debounce(function() {
      this.checkInvoiceNrUnique();
    }, 1000),
    checkInvoiceNrUnique() {
      this.invoiceNrInUse = false;
      this.apiRequest(
        "notes/invoice/" +
          this.$store.state.companyData.activeCompany.uuid +
          "/conflict/?invoice_nr=" +
          this.pdfData.invoice_nr,
        "get",
        true
      ).then(res => {
        if (res.status === 200) {
          this.invoiceNrInUse = false;
        } else if (res.status === 208) {
          this.invoiceNrInUse = true;
        }
      });
    }
  },
  computed: {
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    },
    disabledPaymentDates() {
      if (this.pdfData.invoice_date) {
        const threshold = this.pdfData.invoice_date;
        threshold.setDate(threshold.getDate() - 1);
        return { to: threshold };
      } else {
        const threshold = new Date();
        threshold.setDate(threshold.getDate() - 1);
        return { to: threshold };
      }
    },
    invoiceDateFormatted() {
      return moment(this.pdfData.invoice_date).format("YYYY-MM-DD");
    },
    paymentDateFormatted() {
      return moment(this.pdfData.payment_date).format("YYYY-MM-DD");
    }
  },
  watch: {
    pdfData: {
      deep: true,
      handler() {
        this.$v.$touch();
      }
    },
    "pdfData.invoice_nr": {
      handler() {
        console.log(this.pdfData.invoice_nr);
        if (this.pdfData.invoice_nr) {
          this.debounceCheckChanges();
        }
      }
    }
  },
  mounted() {
    this.initDates();
  },
  validations() {
    return {
      pdfData: {
        invoice_nr: {
          required
        },
        invoice_date: {
          required
        },
        ref_nr: {
          maxLength: maxLength(20),
          minLength: minLength(2)
        },
        base_invoice: {
          maxLength: maxLength(20)
        },
        client: {
          name: {
            required
          },
          reg_code: {
            required
          }
        }
      }
    };
  }
};
</script>

<style lang="scss">
#invoice-datepicker {
  .vdp-datepicker {
    div {
      @apply mr-4;
      input {
        @apply w-full;
      }
    }
  }
}
#invoice-datepicker-2 {
  .vdp-datepicker {
    div {
      @apply mr-4;
      input {
        @apply w-full;
      }
    }
  }
}
</style>
