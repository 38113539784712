<template>
  <div class="flex flex-col w-1/2 mr-3 px-5" v-if="invoiceInfoLoaded">
    <form-label
      :title="'Arve nr'"
      :required="true"
      :valid="!$v.editables.invoice_nr.$invalid"
      :error="$v.editables.invoice_nr.$error"
    ></form-label>
    <input
      type="number"
      v-model="editables.invoice_nr"
      placeholder="Arve nr"
      pattern="^\d+(?:\.\d{1,2})?$"
      class="mb-2"
    />
    <div class="flex flex-row items-center mb-2 w-full">
      <span class="mr-2">Kreeditarve</span>
      <base-checkbox
        class="ml-2"
        :base-val="invoiceData.credit"
        @valueChanged="
          e => {
            invoiceData.credit = e;
          }
        "
      ></base-checkbox>
    </div>
    <span class="text-sm font-medium ml-2" v-if="invoiceData.credit"
      >Alusarve<span class="font-medium"></span
    ></span>
    <input
      v-if="invoiceData.credit"
      class="mb-2"
      type="text"
      v-model="invoiceData.base_invoice"
      placeholder="Alusarve"
      :class="
        $v.$error && $v.invoiceData.base_invoice.$invalid
          ? 'shadow-dangeroutline'
          : ''
      "
    />
    <form-label
      :title="'Kuupäev'"
      :required="true"
      :valid="!$v.editables.invoice_date.$invalid"
      :error="$v.editables.invoice_date.$error"
    ></form-label>
    <div class="flex flex-row items-center rounded-xl w-full mb-2">
      <date-picker
        v-model="editables.invoice_date"
        :class="[topCalendarShowing ? 'is-active' : '']"
        @opened="handleTopCalendarShow"
        @closed="handleTopCalendarClose"
        :language="ee"
        :format="formatDate"
        :monday-first="true"
        :disabled-dates="disabledDates"
      />
    </div>
    <form-label :title="'Tähtaeg'" :required="false"></form-label>
    <div class="flex flex-row items-center rounded-xl w-full mb-2">
      <date-picker
        v-model="editables.payment_date"
        :class="[topCalendarShowing ? 'is-active' : '']"
        @opened="handleTopCalendarShow"
        @closed="handleTopCalendarClose"
        :language="ee"
        :format="formatDate"
        :monday-first="true"
        :disabled-dates="disabledPaymentDates"
        class="mr-2"
      />
      <div v-if="!editables.payment_date" class="w-6/12 ml-2">
        <select
          class="add-offer-input"
          v-model="tempExpiryDate"
          @change="changeExpiryDate"
        >
          <option value="null" disabled class="text-bordergrey"
            >Vali päevade arv
          </option>
          <option :value="7">7 päeva</option>
          <option :value="14">14 päeva</option>
          <option :value="21">21 päeva</option>
          <option :value="30">30 päeva</option>
        </select>
      </div>
      <div v-if="editables.payment_date">
        <button class="new-button-danger" @click="clearPaymentDate">
          <span class="typcn typcn-times icon"></span>
        </button>
      </div>
    </div>
    <div class="flex flex-col">
      <form-label
        :title="'Viitenumber'"
        :required="false"
        :valid="!$v.editables.ref_nr.$invalid"
        :error="$v.editables.ref_nr.$error"
        :error-text="'2-20 kohaline number'"
      ></form-label>
      <input type="text" class="mb-2" v-model="editables.ref_nr" />
    </div>
  </div>
</template>
<script>
import DatePicker from "vuejs-datepicker";
import { ee } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import { debounce } from "lodash";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "CompanyInvoiceInfo",
  components: { DatePicker },
  props: {
    invoiceData: Object
  },
  data() {
    return {
      ee: ee,
      moment: moment,
      tempExpiryDate: null,
      topCalendarShowing: false,
      editables: {
        inited: false,
        invoice_nr: "",
        invoice_date: null,
        payment_date: null,
        ref_nr: ""
      },
      savedTimeout: null,
      saved: false,
      saving: false,
      preventSave: false,
      invoiceInfoLoaded: false
    };
  },
  methods: {
    handleTopCalendarShow() {
      this.topCalendarShowing = true;
    },
    handleTopCalendarClose() {
      this.topCalendarShowing = false;
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    changeExpiryDate() {
      if (!this.preventSave) {
        if (this.invoiceData.pdf_info.invoice_date) {
          this.editables.payment_date = moment(
            this.invoiceData.pdf_info.invoice_date
          )
            .add(this.tempExpiryDate, "days")
            .toDate();
        } else {
          this.editables.payment_date = moment()
            .add(this.tempExpiryDate, "days")
            .toDate();
        }
        this.tempExpiryDate = null;
      }
    },
    initEditables() {
      this.editables.inited = false;
      this.editables.invoice_date = moment(
        this.invoiceData.invoice_date
      ).toDate();
      if (this.invoiceData.pdf_info.payment_date) {
        this.editables.payment_date = moment(
          this.invoiceData.pdf_info.payment_date
        ).toDate();
      }
      this.editables.invoice_nr = this.invoiceData.pdf_info.invoice_nr;
      this.editables.ref_nr = this.invoiceData.pdf_info.ref_nr;
      this.editables.inited = true;
      this.preventSave = false;
      this.invoiceInfoLoaded = true;
    },
    clearPaymentDate() {
      this.editables.payment_date = null;
    },
    editPdfInfo() {
      const data = {};
      let self = this;
      this.saved = false;
      this.saving = true;

      if (this.savedTimeout) {
        clearTimeout(this.savedTimeout);
        this.savedTimeout = null;
      }

      if (
        this.invoiceData.pdf_info.invoice_date !== this.invoiceDateFormatted
      ) {
        data.invoice_date = this.invoiceDateFormatted;
      }
      if (
        this.invoiceData.pdf_info.payment_date !== this.paymentDateFormatted
      ) {
        if (!this.editables.payment_date) {
          data.payment_date = null;
        } else {
          data.payment_date = this.paymentDateFormatted;
        }
      }
      if (this.invoiceData.pdf_info.invoice_nr !== this.editables.invoice_nr) {
        data.invoice_nr = this.editables.invoice_nr;
      }
      if (this.invoiceData.pdf_info.ref_nr !== this.editables.ref_nr) {
        data.ref_nr = this.editables.ref_nr;
      }
      if (Object.keys(data).length > 0) {
        this.apiRequest(
          "notes/invoice/" +
            this.$store.state.companyData.activeCompany.uuid +
            "/" +
            this.$store.state.invoiceViewer.companyInvoice.data +
            "/edit/",
          "patch",
          true,
          data
        ).then(res => {
          if (res.status === 200) {
            this.saving = false;

            this.$emit("pdfInfoUpdated");
            this.saved = true;
            this.savedTimeout = setTimeout(function() {
              self.saved = false;
            }, 5000);
          }
        });
      } else {
        this.savedTimeout = setTimeout(function() {
          self.saved = false;
        }, 5000);
      }
      this.saving = false;
    },
    debounceSaveChanges: debounce(function() {
      if (this.editables.inited && !this.preventSave) {
        this.editPdfInfo();
      }
    }, 1000)
  },
  computed: {
    disabledDates() {
      const threshold = new Date();
      threshold.setDate(threshold.getDate() - 1);
      return { to: threshold };
    },
    disabledPaymentDates() {
      if (this.invoiceData.pdf_info.invoice_date) {
        const threshold = this.moment(
          this.invoiceData.pdf_info.invoice_date
        ).toDate();
        threshold.setDate(threshold.getDate() - 1);
        return { to: threshold };
      } else {
        const threshold = new Date();
        threshold.setDate(threshold.getDate() - 1);
        return { to: threshold };
      }
    },
    invoiceDateFormatted() {
      return moment(this.editables.invoice_date).format("YYYY-MM-DD");
    },
    paymentDateFormatted() {
      if (!this.editables.payment_date) {
        return null;
      }
      return moment(this.editables.payment_date).format("YYYY-MM-DD");
    }
  },
  mounted() {
    this.initEditables();
  },
  watch: {
    editables: {
      deep: true,
      handler() {
        this.$v.$touch();
        if (!this.$v.$invalid && !this.preventSave) {
          this.debounceSaveChanges();
        }
      }
    }
  },

  validations() {
    return {
      editables: {
        invoice_date: {
          required
        },
        invoice_nr: {
          required
        },
        ref_nr: {
          maxLength: maxLength(20),
          minLength: minLength(2)
        },
        base_invoice: {
          maxLength: maxLength(20)
        }
      }
    };
  }
};
</script>
