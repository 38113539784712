<template>
  <div>
    <div class="flex flex-col mb-8">
      <div
        class="flex items-center mb-6 w-4/12 mobile:w-full"
        :disabled="existingClient"
        :class="existingClient ? 'disabled' : ''"
      >
        <base-checkbox
          :baseVal="client.is_company"
          @valueChanged="
            e => {
              client.is_company = e;
            }
          "
        ></base-checkbox>
        <h4 class="ml-2">Klient on ettevõte</h4>
      </div>
      <form-label
        :title="client.is_company ? 'Ettevõtte nimi' : 'Nimi'"
        :required="true"
        :valid="!$v.client.name.$invalid"
        :error="$v.client.name.$error"
      ></form-label>
      <input
        class="mb-4 add-project-input"
        :class="[
          $v.$error && $v.client.name.$invalid ? 'shadow-dangeroutline' : '',
          existingClient ? 'disabled' : ''
        ]"
        :disabled="existingClient"
        v-model="client.name"
      />
      <form-label
        :title="client.is_company ? 'Ettevõtte registrikood' : 'Isikukood'"
        :required="client.is_company"
        :valid="!$v.client.reg_code.$invalid"
        :error="
          !client.is_company
            ? $v.client.reg_code.$error &&
              (!$v.client.reg_code.maxLength ||
                !$v.client.reg_code.minLength ||
                !$v.client.reg_code.numeric ||
                !$v.client.reg_code.validateIdCodeControlNumber)
            : $v.client.reg_code.$error
        "
        :error-text="
          !client.is_company
            ? $v.client.reg_code.$error && !$v.client.reg_code.numeric
              ? 'Isikukood tohib koosneda ainult numbritest'
              : $v.client.reg_code.$error &&
                (!$v.client.reg_code.maxLength || !$v.client.reg_code.minLength)
              ? 'Isikukood peab olema 11 numbrit pikk'
              : $v.client.reg_code.$error &&
                !$v.client.reg_code.validateIdCodeControlNumber
              ? 'Isikukoodi kontrollnumber ole korrektne'
              : 'Isikukood pole korrektne'
            : 'Registrikood peab olema täidetud'
        "
      ></form-label>
      <input
        class="mb-4 add-project-input"
        :class="existingClient ? 'disabled' : ''"
        v-model="client.reg_code"
        :disabled="existingClient"
      />
      <form-label
        title="Ettevõtte KMRK kood"
        v-if="client.is_company"
      ></form-label>
      <input
        v-if="client.is_company"
        class="mb-4 add-project-input"
        :class="existingClient ? 'disabled' : ''"
        v-model="client.tax_code"
        :disabled="existingClient"
      />
      <form-label
        :title="client.is_company ? 'Ettevõtte meiliaadress' : 'Meiliaadress'"
      ></form-label>
      <input
        class="mb-4 add-project-input"
        v-model="client.email"
        :disabled="existingClient"
        :class="[
          $v.$error && $v.client.email.$invalid ? 'shadow-dangeroutline' : '',
          existingClient ? 'disabled' : ''
        ]"
      />
      <form-label
        :title="
          client.is_company ? 'Ettevõtte telefoninumber' : 'Telefoninumber'
        "
      ></form-label>
      <div class="w-full flex mb-6">
        <select
          v-model="countryCode"
          class="mobile:w-3/12 medium:w-3/12 w-2/12 mr-2"
          :disabled="existingClient"
          :class="existingClient ? 'disabled' : ''"
        >
          <option value="+372"><img src="" />+372</option>
        </select>
        <input
          class="mobile:w-9/12 medium:w-9/12 w-10/12 add-project-input"
          v-model="phoneNumberInput"
          :disabled="existingClient"
          :class="existingClient ? 'disabled' : ''"
          @input="assignPhoneNumber"
          type="tel"
          pattern="[0-9]*"
        />
      </div>
      <form-label
        :title="client.is_company ? 'Ettevõtte aadress' : 'Kliendi aadress'"
      ></form-label>
      <location-inaddress
        v-if="!client.address"
        :text="
          client.is_company
            ? 'Otsi ettevõtte aadressi'
            : 'Otsi kliendi aadressi'
        "
        @locationChanged="handleClientLocationChange"
        class="mb-6"
      >
      </location-inaddress>
      <div v-if="client.address" class="mb-4 flex items-center hidden">
        <span>
          {{
            existingClient
              ? client.address.long_address
              : client.address.long_address
          }}
        </span>
        <button
          class="new-button-danger ml-2"
          @click="client.address = null"
          v-if="!existingClient"
        >
          <span class="icon">
            <img src="/bc21/trash.svg" class="filter-to-white" />
          </span>
          <span class="label pl-2 pr-3">Tühjenda</span>
        </button>
      </div>
      <div class="flex flex-col" v-if="client.address">
        <div class="flex flex-row mb-2">
          <input
            type="text"
            class="w-7/12 mr-2"
            v-model="client.address.street"
            placeholder="Tänav, maja, korter"
          />
          <input
            type="text"
            class="w-5/12 ml-2"
            v-model="client.address.locality"
            placeholder="Küla/alev/Maakond"
          />
        </div>
        <div class="flex flex-row">
          <input
            type="text"
            v-model="client.address.post_index"
            class="mr-2 w-4/12"
            placeholder="Postiindeks"
          />
          <input
            type="text"
            v-model="client.address.city"
            class="mx-2 w-4/12"
            placeholder="Linn"
          />
          <input
            type="text"
            v-model="client.address.country"
            class="ml-2 w-4/12"
            placeholder="Riik"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationInaddress from "@/components/reusable/LocationInaddress";
import {
  email,
  required,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators";

const validateIdCodeControlNumber = val => {
  let i;
  if (val === null || val === "") {
    return true;
  }
  let multiplier1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1],
    multiplier2 = [3, 4, 5, 6, 7, 8, 9, 1, 2, 3],
    mod,
    total = 0;

  for (i = 0; i < 10; i++) {
    total += val.charAt(i) * multiplier1[i];
  }
  mod = total % 11;

  total = 0;
  if (10 === mod) {
    for (i = 0; i < 10; i++) {
      total += val.charAt(i) * multiplier2[i];
    }
    mod = total % 11;
    if (10 === mod) {
      mod = 0;
    }
  }
  return mod === parseInt(val.charAt(10));
};

export default {
  name: "InvoiceAddClientForm",
  components: {
    LocationInaddress
  },
  props: {
    existingClient: Object
  },
  data() {
    return {
      client: {
        name: null,
        email: null,
        telephone_number: null,
        address: null,
        reg_code: null,
        tax_code: null,
        is_company: false,
        existing: false
      },
      phoneNumberInput: "",
      contactDetails: false,
      clientContacts: [],
      isCompany: false,
      countryCode: "+372"
    };
  },
  methods: {
    handleClientLocationChange(e) {
      this.client.address = {
        ads_oid: e.ads_oid,
        latitude: e.viitepunkt_b,
        longitude: e.viitepunkt_l,
        long_address: e.ipikkaadress,
        street: e.aadresstekst,
        locality: e.asustusyksus,
        city: e.omavalitsus,
        country: "Eesti"
      };
    },
    assignPhoneNumber() {
      this.client.telephone_number = this.fullPhoneNumber;
    },
    saveClientContact(contact) {
      this.clientContacts.push(contact);
      this.$emit("clientContactsUpdated", this.clientContacts);
    },
    removeContact(index) {
      this.clientContacts.splice(index, 1);
      this.$emit("clientContactsUpdated", this.clientContacts);
    }
  },
  validations() {
    let validationObj = {
      client: {
        name: { required },
        email: { email }
      },
      address: { required }
    };
    if (!this.client.is_company) {
      validationObj.client.reg_code = {
        validateIdCodeControlNumber,
        minLength: minLength(11),
        maxLength: maxLength(11),
        numeric
      };
    } else {
      validationObj.client.reg_code = {
        required
      };
    }
    if (
      this.client.telephone_number !== null &&
      this.client.telephone_number.length > 0
    ) {
      validationObj.telephone_number = { required };
    }
    console.log(validationObj);
    return validationObj;
  },
  computed: {
    fullPhoneNumber() {
      return this.countryCode + this.phoneNumberInput.replace(" ", "");
    }
  },
  watch: {
    client: {
      deep: true,
      handler() {
        this.$v.$touch();
        this.$emit("clientUpdated", this.client);
      }
    },
    contactDetails() {
      this.$emit("editingContact", this.contactDetails);
    }
  }
};
</script>

<style></style>
