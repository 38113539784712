<template>
  <div
    class="w-full grid grid-cols-12 mobile:w-full mobile:grid-cols-1 mobile:grid-rows-8 text-sm"
    v-if="tempServiceInited"
    :class="showTemplateSelector ? 'items-start' : 'items-center'"
  >
    <div
      class="flex relative col-span-3 mobile:col-span-1 justify-center mobile:mb-2 mobile:items-start mobile:flex-col"
    >
      <span class="hidden mobile:flex">Kirjeldus</span>
      <div
        class="flex w-full relative items-center bg-offwhite flex-col rounded-xl"
        :class="[descFocused ? 'shadow-outline' : 'shadow']"
      >
        <div class="flex w-full">
          <input
            type="text"
            class="add-offer-input w-11/12 flex-auto shadow-none focus:shadow-none"
            @focus="openTemplateSelector"
            @blur="descFocused = false"
            @input="activateTemplateSelector"
            v-model="tempService.name"
            :class="
              $v.$error && $v.tempService.name.$invalid
                ? 'shadow-dangeroutline'
                : ''
            "
          />
          <div
            v-if="!disableTemplates"
            class="justify-center items-center flex px-1"
            @click="openTemplateSelector"
          >
            <span
              class="typcn typcn-arrow-sorted-down transform duration-100"
              :class="showTemplateSelector ? 'rotate-180' : ''"
            ></span>
          </div>
        </div>
        <div
          v-if="showTemplateSelector"
          class="justify-start template-selector z-10"
          :class="
            serviceTemplates.filter(x =>
              x.name.toLowerCase().includes(tempService.name.toLowerCase())
            ).length > 4
              ? 'overflow-y-scroll'
              : ''
          "
          ref="templateSelector"
        >
          <div
            v-if="
              serviceTemplates.filter(x =>
                x.name.toLowerCase().includes(tempService.name.toLowerCase())
              ).length === 0
            "
            class="py-2 rounded-b-xl px-2"
          >
            Vasted puuduvad
          </div>
          <div
            v-for="(match, index) in serviceTemplates.filter(x =>
              x.name.toLowerCase().includes(tempService.name.toLowerCase())
            )"
            :key="match.id"
            class="py-1 px-2 w-full hover:bg-offwhite cursor-pointer"
            @click="setTemplateAsService(match)"
            :class="
              index ===
              serviceTemplates.filter(x =>
                x.name.toLowerCase().includes(tempService.name.toLowerCase())
              ).length -
                1
                ? 'rounded-b-xl'
                : ''
            "
          >
            {{ match.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center px-1 mobile:flex-col mobile:px-0">
      <span class="hidden mobile:flex">Kogus</span>
      <input
        type="number"
        class="add-offer-input"
        step="0.0001"
        min="0"
        v-model="tempService.amount"
      />
    </div>
    <div class="flex justify-center px-1 mobile:flex-col mobile:px-0">
      <span class="hidden mobile:flex">Ühik</span>
      <select class="add-offer-input" v-model="tempService.unit"
        ><option :value="1">tk</option>
        <option :value="2">h</option>
        <option :value="11">m</option>
        <option :value="3">km</option>
        <option :value="4">l</option>
        <option :value="5">m²</option>
        <option :value="6">m³</option>
        <option :value="8">kg</option>
        <option :value="12">t</option>
        <option :value="7">päev</option>
        <option :value="9">kuu</option>
      </select>
    </div>
    <div class="flex justify-center px-1 mobile:px-0 mobile:flex-col">
      <span class="hidden mobile:flex">Ühikuhind (€)</span>
      <input
        type="number"
        step="0.0001"
        class="add-offer-input"
        min="0"
        v-model="tempService.price"
      />
    </div>
    <div class="flex justify-center px-1 mobile:px-0 mobile:flex-col">
      <span class="hidden mobile:flex">Allahindlus</span>
      <input
        type="number"
        step="0.01"
        class="add-offer-input"
        min="0"
        v-model="tempService.discount"
      />
    </div>
    <div
      class="flex justify-start pl-3 mobile:flex-col items-center py-2 mobile:items-start mobile:px-0"
    >
      <span class="hidden mobile:flex self-center mobile:self-start "
        >Summa (-km)</span
      >{{ calculatedSumNoVat }}€
    </div>
    <div class="flex justify-center px-1 mobile:flex-col mobile:px-0">
      <span class="hidden mobile:flex">KM%</span>
      <select class="add-offer-input" v-model="tempService.vat">
        <option :value="0">0%</option>
        <option :value="20">20%</option>
      </select>
    </div>
    <div class="flex justify-start mobile:flex-col pl-3 py-2 mobile:px-0">
      <span class="hidden mobile:flex">KM</span>{{ calculatedVat }}€
    </div>
    <div class="flex justify-start mobile:flex-col py-2">
      <span class="hidden mobile:flex">Summa</span>
      {{ calculatedSum }}€
    </div>
    <div class="flex justify-center mobile:hidden px-1 py-1">
      <button
        class="bg-danger rounded-xl h-8 w-8 text-xl flex justify-center items-center hover:bg-dangerdark hover:shadow-dangeroutline"
        @click="removeServiceModalActive = true"
      >
        <span class="typcn typcn-times text-white"></span>
      </button>
    </div>
    <div class="mobile:flex justify-center mobile:justify-start px-1 hidden">
      <button
        class="new-button-danger"
        @click="removeServiceModalActive = true"
      >
        <span class="typcn typcn-times icon"></span>
        <span class="label">Eemalda rida</span>
      </button>
    </div>
    <confirm-modal
      v-if="removeServiceModalActive"
      text="Olete kindel, et soovite valitud rea eemaldada?"
      @confirm="removeService()"
      @closeModal="removeServiceModalActive = false"
    ></confirm-modal>
  </div>
</template>

<script>
import ConfirmModal from "@/components/reusable/ConfirmModal";
import { debounce } from "lodash";
import { maxLength } from "vuelidate/lib/validators";
export default {
  components: {
    ConfirmModal
  },
  props: {
    index: Number,
    service: Object,
    serviceTemplates: {
      type: Array,
      default: () => {
        return [];
      }
    },
    activeTemplateSelector: Number,
    disableTemplates: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      vat: 0.2,
      tempService: null,
      tempServiceInited: false,
      removeServiceModalActive: false,
      foundMatches: [],
      descFocused: false,
      showTemplateSelector: false
    };
  },
  mounted() {
    this.initTempData();
  },
  methods: {
    checkMaxLength() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
    activateTemplateSelector() {
      if (!this.disableTemplates) {
        this.showTemplateSelector = true;
      }
    },
    initTempData() {
      this.tempService = this.service;
      this.tempService.calculatedVat = this.calculatedVat;
      this.tempService.calculatedSumNoVat = this.calculatedSumNoVat;
      this.tempService.calculatedSum = this.calculatedSum;
      this.tempServiceInited = true;
    },
    debounceServiceUpdate: debounce(function() {
      let serviceObj = {};

      serviceObj = this.tempService;
      serviceObj.calculatedVat = this.calculatedVat;
      serviceObj.calculatedSumNoVat = this.calculatedSumNoVat;
      serviceObj.calculatedSum = this.calculatedSum;
      this.$emit("serviceUpdated", serviceObj);
    }, 500),
    removeService() {
      if (this.tempService.id) {
        this.$emit("removeService", this.tempService.id);
      } else {
        this.$emit("removeService", this.index);
      }
    },
    setTemplateAsService(match) {
      this.tempService.name = match.name;
      this.tempService.amount = match.amount;
      this.tempService.price = match.price;
      this.tempService.vat = match.vat;
      this.tempService.unit = match.unit;
      this.showTemplateSelector = false;
    },
    debounceServiceMenu: debounce(function() {
      if (this.disableTemplates) {
        this.showTemplateSelector = true;
      }
    }, 300),
    openTemplateSelector() {
      if (this.disableTemplates) {
        return;
      }
      if (!this.showTemplateSelector) {
        this.$emit("openTemplateSelector", { id: this.index });
        this.showTemplateSelector = true;
      } else {
        this.showTemplateSelector = false;
      }
    }
  },
  computed: {
    calculatedVat() {
      let vat, sum;
      if (this.tempService.price && this.tempService.amount) {
        if (this.tempService.vat === 20) {
          if (this.tempService.discount > 0) {
            sum =
              parseFloat(this.tempService.price) *
              parseFloat(this.tempService.amount) *
              (1 - parseFloat(this.tempService.discount) / 100);
          } else {
            sum =
              parseFloat(this.tempService.price) *
              parseFloat(this.tempService.amount);
          }
          vat = sum * parseFloat(this.vat);
        } else {
          vat = 0;
        }
        return vat.toFixed(2);
      } else {
        return 0;
      }
    },
    calculatedSumNoVat() {
      if (this.tempService.price && this.tempService.amount) {
        let sum;
        if (this.tempService.discount > 0) {
          sum =
            parseFloat(this.tempService.price) *
            parseFloat(this.tempService.amount) *
            (1 - parseFloat(this.tempService.discount) / 100);
        } else {
          sum =
            parseFloat(this.tempService.price) *
            parseFloat(this.tempService.amount);
        }
        return sum.toFixed(2);
      } else {
        return 0.0;
      }
    },
    calculatedSum() {
      if (this.tempService.price && this.tempService.amount) {
        let sum =
          parseFloat(this.calculatedSumNoVat) + parseFloat(this.calculatedVat);

        return sum.toFixed(2);
      } else {
        return 0;
      }
    }
  },
  watch: {
    service: {
      deep: true,
      handler() {
        if (this.tempServiceInited) {
          this.debounceServiceUpdate();
        }
      }
    },
    activeTemplateSelector() {
      if (this.index !== this.activeTemplateSelector) {
        this.showTemplateSelector = false;
      } else {
        this.showTemplateSelector = true;
      }
    }
  },
  validations: {
    tempService: {
      name: {
        maxLength: maxLength(128)
      }
    }
  }
};
</script>
<style lang="scss">
.add-offer-input {
  @apply bg-offwhite shadow w-full;
  &:focus {
    @apply shadow-outline;
  }
}
.template-selector {
  max-height: 300px;
  @apply flex flex-col bg-white w-full shadow rounded-b-xl;
}
</style>
