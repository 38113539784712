<template>
  <div class="flex flex-col">
    <invoices class="hidden"></invoices>
    <company-invoices
      v-if="!$store.state.invoiceViewer.companyInvoice.show"
    ></company-invoices>
    <company-invoice
      v-if="$store.state.invoiceViewer.companyInvoice.show"
    ></company-invoice>
  </div>
</template>

<script>
import Invoices from "@/components/company/invoices/Invoices";
import CompanyInvoices from "@/components/company/invoices/CompanyInvoices";
import CompanyInvoice from "@/components/company/invoices/CompanyInvoice";

export default {
  components: {
    CompanyInvoice,
    CompanyInvoices,
    Invoices
  }
};
</script>
